import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'survey',
        path: `${AUTH_PREFIX_PATH}/survey`,
        component: React.lazy(() => import('views/pages/Survey')),
    },
    {
        key: 'update_survey',
        path: `${AUTH_PREFIX_PATH}/update_survey`,
        component: React.lazy(() => import('views/pages/Market/UpdateSurvey')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'error-page-1',
        path: `${AUTH_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    },
    {
        key: 'home',
        path: `${AUTH_PREFIX_PATH}/home`,
        // component: React.lazy(() => import('views/app-views/dashboards/default')),
        component: React.lazy(() => import('views/pages/Home2')),
    },
    {
        key: 'dashboard.feeds',
        path: `${AUTH_PREFIX_PATH}/social_feeds`,
        // component: React.lazy(() => import('views/app-views/dashboards/default')),
        component: React.lazy(() => import('views/pages/SocialMediaFeeds')),
    },
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        // component: React.lazy(() => import('views/app-views/dashboards/default')),
        component: React.lazy(() => import('views/pages/LT')),
    },
    // {
    //     key: 'login',
    //     path: `${APP_PREFIX_PATH}/login-1`,
    //     component: React.lazy(() => import('views/auth-views/authentication/login')),
    //     meta: {
    //         blankLayout: true
    //     }
    // },
    // {
    //     key: 'register',
    //     path: `${APP_PREFIX_PATH}/register`,
    //     component: React.lazy(() => import('views/auth-views/authentication/register')),
    //     meta: {
    //         blankLayout: true
    //     }
    // },
    {
        key: 'error-page-1',
        path: `${APP_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
        meta: {
            blankLayout: true
        }
    },
    // {
    //     key: 'pages.setting',
    //     path: `${APP_PREFIX_PATH}/pages/setting/*`,
    //     component: React.lazy(() => import('views/app-views/pages/setting')),
    // },
    {
        key: 'chat',
        path: `${APP_PREFIX_PATH}/chat`,
        component: React.lazy(() => import('views/pages/Position')),
    },
    {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        component: React.lazy(() => import('views/pages/ProjectList')),
    },
    // {
    //     key: 'broker.login',
    //     path: `${APP_PREFIX_PATH}/broker/:broker/*`,
    //     component: React.lazy(() => import('views/pages/BrokerLogin')),
    // },
    {
        key: 'profile',
        path: `${APP_PREFIX_PATH}/profile`,
        component: React.lazy(() => import('views/pages/Profile')),
    },
    {
        key: 'poll',
        path: `${APP_PREFIX_PATH}/poll`,
        component: React.lazy(() => import('views/pages/Market/PollForm')),
    },
]